import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Squat Clean 4×4\\@75%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 700ft Sled Pull (+90/45`}{`#`}{` on sled) `}<em parentName="p">{`(down&back x5)`}</em></p>
    <p>{`3 Rounds of:`}</p>
    <p>{`12-Power Cleans (135/95)(RX+155/105)`}</p>
    <p>{`48-Double Unders`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      